<template>
    <HeaderSection v-bind="headerProps" />
    <SocialBox v-bind="socialProps" />
    <section class="hero-media">
		<Carousel :items="mediaInfo" />
	</section>

    <div class="content-wrap">
		<div class="entry entry-page">
			<h2 class="entry-title">{{ title }}</h2>
			<div class="entry-content">
                <p class="mb-7">{{ content }}</p>
                <br>
				<div class="grid-container">
					<div class="grid-column">
						<div>
							<div class="message d-none">
								<div id="contact_alert" class="alert notice"></div>
							</div>
                            <Inputs name="name" label="" placeholder="Your name&hellip;" type="text" v-model:value="contact.name" :isError="contact.nameError" helpText="Please type your name" />
                            <Inputs name="email" label="" placeholder="Your email&hellip;" type="text" v-model:value="contact.email" :isError="contact.emailError" helpText="Please type your email" />
                            <Inputs name="phone" label="" placeholder="Your phone&hellip;" type="text" inputMask="(###) ###-####" v-model:value="contact.phone" :isError="contact.phoneError" helpText="Please type your phone" />
                            <Textarea label="" placeholder="Your message&hellip;" v-model:value="contact.comment" :rows="10" customClass="mb-5" :isError="contact.commentError" helpText="Please type your message" />
                            <Button @click="submit" class="button submit-btn bg-black text-white" size="size" variant="abc">Send message</Button>
                        </div>
					</div>
					<div class="grid-column">
						<h4 class="no-bottom text-3xl">Address:</h4>
						<p class="mb-7"><span v-html="address" /> <br> <a href="https://www.google.com/maps/place/1814+W+Jefferson+Blvd,+Los+Angeles,+CA+90018/@34.0255145,-118.309335,3a,75y,236h,90.12t/data=!3m6!1e1!3m4!1srA51nUqlb1iXjG7WNgfl-w!2e0!7i16384!8i8192!4m7!3m6!1s0x80c2b80e6df4cc85:0x67b87f61d10746e3!8m2!3d34.0253575!4d-118.3093952!10e5!16s%2Fg%2F11b8vgsxfx?entry=ttu" target="_blank">via Google Maps</a></p>

						<h4 class="no-bottom text-3xl">Phone:</h4>
						<p class="mb-7"><span  v-for="(phone, index) in phones" :key="index">{{ phone }}<br></span> </p>

						<h4 class="no-bottom text-3xl">Email:</h4>
						<p class="mb-7"><a :href="`mailto:${email}`" v-for="(email, index) in emails" :key="index">{{ email }}<br></a></p>
					</div>
				</div>
            </div>
        </div>
        <CopyRight v-bind="footerProps" />
    </div>
</template>

<script>
import HeaderSection from '../../organisms/Header/Header.vue';
import Inputs from '../../molecules/Inputs/Component/Component.vue';
import Textarea from '../../molecules/Textareas/Simple/Simple.vue';
import Button from '../../atoms/Button/Button.vue';
import SocialBox from "../../molecules/SocialBox/SocialBox.vue";
import CopyRight from '../../molecules/CopyRight/CopyRight.vue';
import { footerProps } from '../../constants';
import moment from 'moment';
import Carousel from '../../organisms/Carousel/Carousel.vue';

/**
 * 
 */
export default {
    components: {
        HeaderSection,
        Inputs,
        Textarea,
        SocialBox,
        CopyRight,
        Carousel
    },
    props: {
        headerProps: {
            type: Object,
            default: () => null
        },
        socialProps: {
            type: Object,
            default: () => null
        },
        title: {
            type: String,
            default: () => ""
        },
        content: {
            type: String,
            default: () => ""
        },
        address: {
            type: String,
            default: () => ""
        },
        phones: {
            type: Array,
            default: () => []
        },
        emails: {
            type: Array,
            default: () => []
        },
        mediaInfo: {
            type: Array,
            default: () => []
        }
    },
    beforeCreate() {
        // this.$nextTick().then(() => {
            document.body.classList.add('contact');
            document.body.classList.remove('sb-main-padded');
        // })
    },
    data() {
        return {
            contact: {
                name: '',
                nameError: false,
                email: '',
                emailError: false,
                comment: '',
                commentError: false,
                phone: null,
                phoneError: false,
            },
            footerProps,
            moment
        }
    },
    watch: {
        contact: {
            handler(val) {
                if (this.contact.name) {
                    this.contact.nameError = false;
                }
                if (this.contact.email) {
                    this.contact.emailError = false;
                }
                if (this.contact.comment) {
                    this.contact.commentError = false;
                }
                if (this.contact.phone) {
                    this.contact.phoneError = false;
                }
            },
            deep: true
        }
    },
    methods: {
        async submit() {
            if (!this.contact.name) {
                this.contact.nameError = true;
                return false;
            }
            if (!this.contact.email) {
                this.contact.emailError = true;
                return false;
            }
            if (!this.contact.phone) {
                this.contact.phoneError = true;
                return false;
            }
            if (!this.contact.comment) {
                this.contact.commentError = true;
                return false;
            }
            const {saveContactRequest} = await this.actions.user.saveContactRequest({
                data: {
                name: this.contact.name,
                emails: {set: [this.contact.email]},
                phones: {set: [this.contact.phone]},
                comment: this.contact.comment,
                subject: 'Contact Request'
                }
            });
            console.log(saveContactRequest, 'saveContactRequest');
            const result = this.actions.user.requestSupport({
                name: this.contact.name,
                phone: this.contact.phone,
                email: this.contact.email,
                message: this.contact.comment,
                subject: "Starlight Request Form"
            });
            if (result) {
                // this.actions.alert.showSuccess({message: 'Sent your message successfully!'})
                this.contact = {
                    name: '',
                    nameError: false,
                    email: '',
                    emailError: false,
                    comment: '',
                    commentError: false,
                    phone: null,
                    phoneError: false,
                };
                this.$router.push({name: "Contact Success"});
            } else {
                this.actions.alert.showError({message: 'Something went wrong!'})
            }
        }
    }
}
</script>
<style>
</style>