<template>
  <ContactTemplate
    :headerProps="headerProps"
    :socialProps="socialProps"
    :title="title"
    :content="content"
    :address="address"
    :phones="phones"
    :emails="emails"
    :mediaInfo="mediaInfo"
  />
</template>

<script>
import ContactTemplate from "@/components/templates/Contact/Contact";
import { headerProps, socialProps, contact } from "@/components/constants.js";
import heroImg from "@/assets/images/sample-hero.jpg";
export default {
  components: {
    ContactTemplate,
  },
  data() {
    return {
      headerProps: { ...headerProps, currentRoute: this.$route.name },
      socialProps: socialProps,
      title: contact.title,
      mediaInfo: [{ 
        image: "https://starlight-media.s3.us-west-2.amazonaws.com/PHOTOSHOOTS/APRIL+2023+-+CHELLSEA+ELLE/Adamari.png" 
      }],
      content: contact.content,
      address: contact.address,
      phones: contact.phones,
      emails: contact.emails,
    };
  },
  methods: {
  }
};
</script>

<style scoped></style>
